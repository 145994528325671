<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      :items="eventLogs"
      :loading="loading"
      :options.sync="options"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :server-items-length="getEventLogsCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
      class="elevation-1"
    >
      <template v-slot:top>
        <!--          <v-toolbar v-if="currentRouteName !== 'LogEvents' && currentRouteName !== 'LogStatuses'" flat>
          <v-toolbar-title>Події пристроїв (Терміналів та Автоматів)</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>-->
        <v-container fluid>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-btn color="primary" @click="paginateTo">
                <v-icon>mdi-autorenew</v-icon>
                Оновити дані
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-2 mb-4">
            <v-col cols="12" sm="6" md="3" lg="2">
              <v-datetime-picker
                v-model="fromDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                timeFormat="HH:mm:ss"
                dateFormat="dd-MM-yyyy"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час від"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" offset-md="1">
              <v-datetime-picker
                v-model="toDate"
                @input="paginateTo"
                :textFieldProps="textFieldProps"
                :datePickerProps="datePickerProps"
                :timePickerProps="timePickerProps"
                dateFormat="dd-MM-yyyy"
                timeFormat="HH:mm:ss"
                clear-text="очистити"
                ok-text="так"
                label="Дата та час до"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-checkbox
              v-model="isServerTime"
              @change="paginateTo"
              class="ml-3 mb-4"
              label="Використовувати серверний час"
              hide-details
            ></v-checkbox>
            <v-checkbox v-model="isEventTypeVisible" class="ml-3 mb-4" label="Відображати “Група”" hide-details></v-checkbox>
          </v-row>
        </v-container>
      </template>
      <template v-slot:header>
        <thead>
          <tr>
            <th style="border-right: thin solid rgba(0, 0, 0, 0.12)"></th>
            <th
              :colspan="isEventTypeVisible ? 4 : 3"
              class="text-center"
              style="border-right: thin solid rgba(0, 0, 0, 0.12); border-top: thin solid rgba(0, 0, 0, 0.12)"
            >
              Подія
            </th>
            <th colspan="8" class="text-center" style="border-top: thin solid rgba(0, 0, 0, 0.12)">Місце</th>
          </tr>
        </thead>
      </template>
      <template v-slot:header.event_type="{ header }">
        <TableTitleFilter
          :values="selectedEventTypes"
          :options="additionalInfoEventTypes"
          :options-meta="additionalInfoEventTypesMeta"
          :options-params="eventTypesOptionsData"
          :title-text="header.text"
          :checkbox-group-data="eventTypesCheckboxes"
          item-value="id"
          item-text="id"
          @select="(selected) => (selectedEventTypes = selected)"
          @ok="applyFilter('eventTypes')"
          @checkbox="(values) => (selectedEventTypeGroups = values)"
        >
          <template v-slot:item="{ item }">{{ item.name[$currentLocale] }} ({{ item.code }})</template>
          <template v-slot:selection="{ item }">{{ item.name[$currentLocale] }} ({{ item.code }})</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.event="{ header }">
        <TableTitleFilter
          :values="selectedEvents"
          :options="additionalInfoEvents"
          :options-meta="additionalInfoEventsMeta"
          :options-params="eventCodesOptionsData"
          :title-text="header.text"
          item-value="id"
          @select="(selected) => (selectedEvents = selected)"
          @ok="applyFilter('events')"
        >
          <template v-slot:item="{ item }"> {{ item.name[$currentLocale] }} ({{ item.event_code }}) </template>
          <template v-slot:selection="{ item }">{{ item.name[$currentLocale] }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.user_name="{ header }">
        <TableTitleFilter
          :values="selectedUsers"
          :options="initiators"
          :options-meta="initiatorsMeta"
          :options-params="initiatorsOptionsData"
          :title-text="header.text"
          :disabled="disableUsers"
          item-value="id"
          @select="(selected) => (selectedUsers = selected)"
          @ok="applyFilter('users')"
        >
          <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
          <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.company="{ header }">
        <TableTitleFilter
          :values="[selectedCompany]"
          :options="companiesNoFilter"
          :title-text="header.text"
          item-value="id"
          item-text="name"
          disabled
          @select="(selected) => (selectedCompany = selected[0])"
          @ok="applyFilter('companies')"
        />
      </template>
      <template v-slot:header.company_partner="{ header }">
        <TableTitleFilter
          :options="balanceHolders"
          :title-text="header.text"
          @select="setSelectedBalanceHolders"
          @ok="applyFilter('balanceHolders')"
        />
      </template>
      <template v-slot:header.service_point="{ header }">
        <TableTitleFilter
          :options="servicePoints"
          :title-text="header.text"
          @select="setSelectedServicePoints"
          @ok="applyFilter('servicePoints')"
        />
      </template>
      <template v-slot:header.terminal_id="{ header }">
        <TableTitleFilter
          :options="terminals"
          :title-text="header.text"
          item-text="value"
          @select="setSelectedTerminals"
          @ok="applyFilter('terminals')"
        >
          <template v-slot:item="{ item }">T{{ item.value }}</template>
          <template v-slot:selection="{ item }">T{{ item.value }}</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.device_type="{ header }">
        <TableTitleFilter
          :options="connectedDevicesTypesDictionary"
          :title-text="header.text"
          item-value="name"
          item-text="description"
          @select="setSelectedDevicesType"
          @ok="applyFilter('devicesTypes')"
        />
      </template>
      <template v-slot:header.device_id="{ header }">
        <TableTitleFilter :options="devices" :title-text="header.text" @select="setSelectedDevices" @ok="applyFilter('devices')">
          <template v-slot:item="{ item }">{{ item.label }} ({{ item.type }}{{ item.value }})</template>
        </TableTitleFilter>
      </template>
      <template v-slot:header.device_number="{ header }">
        <div class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">{{ header.text }}</div>
            </template>
            <span>Номер автомату</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.terminal_packet_date_time="{ item }">
        <div>{{ item.terminal_packet_date_time | getShortTime }}</div>
        <span class="caption">{{ item.terminal_packet_date_time | getShortDate }}</span>
      </template>
      <template v-slot:item.server_packet_date_time="{ item }">
        <div>{{ item.server_packet_date_time | getShortTime }}</div>
        <span class="caption">{{ item.server_packet_date_time | getShortDate }}</span>
      </template>
      <template v-slot:item.event_type="{ item }">
        {{ item.event_type ? `${item.event_type} (${item.event_type_code})` : '' }}
      </template>
      <template v-slot:item.event="{ item }"> {{ item.event }} ({{ item.event_code }}) </template>
      <template v-slot:item.user_name="{ item }">
        <router-link
          v-if="item.user_name && item.user_id"
          :to="{ name: 'ClientCard', params: { id: currentCompanyId, clientId: item.user_id } }"
          class="text-decoration-none"
        >
          {{ item.user_name }}
        </router-link>
      </template>
      <template v-slot:item.company_partner="{ item }">
        <router-link
          v-if="item.company_partner"
          :to="{ name: 'PartnerCard', params: { id: currentCompanyId, partnerId: item.company_partner_id } }"
          class="text-decoration-none"
        >
          {{ item.company_partner }}
        </router-link>
      </template>
      <template v-slot:item.service_point="{ item }">
        <router-link
          v-if="item.service_point && item.service_point_address"
          :to="{
            name: 'ServicePointCard',
            params: { id: currentCompanyId, servicePointId: item.service_point_id },
            query: { logTab: logsType },
          }"
          class="text-decoration-none"
        >
          {{ item.service_point }}, {{ item.service_point_address }}
        </router-link>
      </template>
      <template v-slot:item.terminal_id="{ item }">
        <router-link
          v-if="item.terminal_id"
          :to="{
            name: 'TerminalCard',
            params: { id: currentCompanyId, terminalId: item.terminal_id },
            query: { logTab: logsType },
          }"
          class="text-decoration-none"
        >
          {{ item.terminal_id }}
        </router-link>
      </template>
      <template v-slot:item.device_id="{ item }">
        <router-link
          v-if="item.device_id"
          :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: item.device_id }, query: { logTab: logsType } }"
          class="text-decoration-none"
        >
          {{ item.device_id }}
        </router-link>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" small @click.stop="openDialogPacket(item.packet, 'info')" v-bind="attrs" v-on="on">
              mdi-package-variant
            </v-icon>
          </template>
          <span>Інфо пакету</span>
        </v-tooltip>
        <v-tooltip v-if="currentRouteName === 'DeviceEventsList' && !!item.service_point_id" bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" small @click="navigateToServicePointCard(item)" v-bind="attrs" v-on="on">
              mdi-eye-outline
            </v-icon>
          </template>
          <span>Переглянути</span>
        </v-tooltip>
        <v-tooltip v-if="isStatusesPage" bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click="openStatusDialog(item)" v-bind="attrs" v-on="on"> mdi-comment-plus-outline </v-icon>
          </template>
          <span>Додаткові параметри</span>
        </v-tooltip>
        <v-tooltip v-if="item.errors" bottom :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-icon small @click.stop="openDialogPacket(item.errors, 'error')" v-bind="attrs" v-on="on" color="error">
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>Переглянути помилку пакету</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="resetData"> Оновити </v-btn>
      </template>
    </v-data-table>

    <LogsPackageModal
      @closePackageDialog="dialogPacket = false"
      :dialogPacket.sync="dialogPacket"
      :dialogPacketType="dialogPacketType"
      :selectedPacket="selectedPacket"
    />
    <v-dialog v-model="statusDialog" max-width="500px" scrollable>
      <v-card>
        <v-card-title class="text-h5 justify-center">{{ statusDialogTitle }}</v-card-title>
        <v-card-text v-if="statusSpecParams">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="(item, index) in statusSpecParams" :key="index">
                  <td>{{ item.name }}</td>
                  <td>{{ item.value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="statusDialog = false"> закрити </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import ConvertDate from '@/mixins/convertDate'
import resetTableData from '@/mixins/resetTableData'
import ManageFiltersOptions from '@/mixins/manageFiltersOptions'
import SetLogsFiltersOptions from '@/mixins/setLogsFiltersOptions'
import OpenLogsPackageDialog from '@/mixins/openLogsPackageDialog'
import CustomAutocompleteFilter from '@/mixins/customAutocompleteFilter'
import { EVENT_TYPE, STATUS_TYPE } from '@/const/constructor/packages-types.enum'
import moment from 'moment'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter.vue'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'
import sortUtils from '@/mixins/sortUtils'

const DEFAULT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
  name: 'Logs',

  components: {
    TableTitleFilter,
    LogsPackageModal: () => import('@/components/dialogs/LogsPackageModal'),
  },

  mixins: [
    CustomAutocompleteFilter,
    convertDevicesTypes,
    ConvertDate,
    ManageFiltersOptions,
    SetLogsFiltersOptions,
    OpenLogsPackageDialog,
    resetTableData,
    sortUtils,
  ],

  props: {
    instanceId: {
      type: Number,
      required: false,
      default: null,
    },
    hideCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
    preselectedEventTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
    preselectedEvents: {
      type: Array,
      required: false,
      default: () => [],
    },
    preselectedUsers: {
      type: Array,
      required: false,
      default: () => [],
    },
    disableUsers: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    MENU_MIN_WIDTH: 450,
    MENU_MAX_WIDTH: 450,
    isInitialized: false,
    statusDialog: false,
    statusDialogTitle: '',
    statusSpecParams: null,
    isEventTypesFilterApplied: false,
    isEventsFilterApplied: false,
    isUsersFilterApplied: false,
    isBalanceHoldersFilterApplied: false,
    isServicePointsFilterApplied: false,
    isTerminalsFilterApplied: false,
    isDevicesTypesFilterApplied: false,
    isDevicesFilterApplied: false,
    loading: false,
    filterLoading: false,
    sortBy: '',
    sortDesc: true,
    options: {},
    additionalInfoEventTypesList: [],
    page: 1,
    isServerTime: false,
    isEventTypeVisible: false,
    textFieldProps: {
      hideDetails: 'auto',
      dense: true,
    },
    datePickerProps: {
      'first-day-of-week': 1,
    },
    timePickerProps: {
      format: '24hr',
      useSeconds: true,
    },
    fromDate: null,
    // toDate: new Date(),
    toDate: null,
    selectedCompany: null,
    selectedUsers: [],
    selectedEventTypes: [],
    selectedEventTypeGroups: [],
    selectedEvents: [],
    selectedPacket: null,
    searchEventTypeData: null,
    searchEventData: null,
    isEventTypesLoading: false,
    isEventsLoading: false,
    servicePointsFilterKey: 0,
    terminalsFilterKey: 0,
    devicesFilterKey: 0,
    initiatorsOptionsData: null,
    eventTypesOptionsData: null,
    eventCodesOptionsData: null,
    eventTypesCheckboxes: null,
  }),

  computed: {
    ...mapState('logs', ['eventLogs']),
    ...mapState('infoConstructor', [
      'additionalInfoSelectedEventType',
      'additionalInfoEvent',
      'additionalInfoEventTypes',
      'additionalInfoEventTypesMeta',
      'additionalInfoEvents',
      'additionalInfoEventsMeta',
    ]),
    ...mapState('admin', ['user']),
    ...mapState('profile', { profile: (state) => state.user }),
    ...mapState('users', ['initiators', 'initiatorsMeta']),
    ...mapState('dictionaries', ['eventTypeGroupsDictionary', 'connectedDevicesTypesDictionary']),
    ...mapState('companies', ['companiesNoFilter', 'currentCompany']),

    ...mapGetters('logs', ['getEventLogsCount']),
    ...mapGetters('companies', [
      'getUserFilter',
      'getBalanceHolderFilter',
      'getServicePointFilter',
      'getTerminalFilter',
      'getDeviceFilter',
    ]),

    computedHeaders() {
      let headers = [
        {
          text: 'Дата і час',
          value: this.isServerTime ? 'server_packet_date_time' : 'terminal_packet_date_time',
          sortable: true,
          divider: true,
          width: 110,
        },
        // { text: 'Група', value: 'event_type', sortable: false },
        { text: this.isEventsPage ? 'Подія' : 'Статус', value: 'event', sortable: false },
        { text: 'Інфо', value: 'info', sortable: false },
        { text: 'Ініціатор подій', value: 'user_name', sortable: false, divider: true },
        {
          text: 'Компанія',
          value: 'company',
          sortable: false,
          cellClass:
            this.currentRouteName === 'ServicePointCard' ||
            this.currentRouteName === 'TerminalCard' ||
            this.currentRouteName === 'DeviceCard' ||
            this.hideCompany
              ? 'd-none'
              : '',
          class:
            this.currentRouteName === 'ServicePointCard' ||
            this.currentRouteName === 'TerminalCard' ||
            this.currentRouteName === 'DeviceCard' ||
            this.hideCompany
              ? 'd-none'
              : '',
        },
        {
          text: 'Партнер',
          value: 'company_partner',
          sortable: false,
          cellClass:
            this.currentRouteName === 'ServicePointCard' ||
            this.currentRouteName === 'TerminalCard' ||
            this.currentRouteName === 'DeviceCard'
              ? 'd-none'
              : '',
          class:
            this.currentRouteName === 'ServicePointCard' ||
            this.currentRouteName === 'TerminalCard' ||
            this.currentRouteName === 'DeviceCard'
              ? 'd-none'
              : '',
        },
        {
          text: 'Точка',
          value: 'service_point',
          sortable: false,
          cellClass:
            this.currentRouteName === 'ServicePointCard' ||
            this.currentRouteName === 'TerminalCard' ||
            this.currentRouteName === 'DeviceCard'
              ? 'd-none'
              : '',
          class:
            this.currentRouteName === 'ServicePointCard' ||
            this.currentRouteName === 'TerminalCard' ||
            this.currentRouteName === 'DeviceCard'
              ? 'd-none'
              : '',
        },
        {
          text: 'Т',
          value: 'terminal_id',
          sortable: false,
          cellClass: this.currentRouteName === 'TerminalCard' ? 'd-none' : '',
          class: this.currentRouteName === 'TerminalCard' ? 'd-none' : '',
        },
        {
          text: 'Тип A',
          value: 'device_type',
          sortable: false,
        },
        { text: 'ID A', value: 'device_id', sortable: false },
        { text: '#A', value: 'device_number', sortable: false },
        { text: '', value: 'actions', sortable: false, width: '100px', align: 'left' },
      ]
      if (this.isEventTypeVisible) {
        headers.splice(1, 0, { text: 'Група', value: 'event_type', sortable: false })
      }
      return headers
    },
    currentCompanyId() {
      return this.$route.params.id || this.currentCompany.id
    },
    currentRouteName() {
      return this.$route.name
    },
    currentPacketEventTypes() {
      if (this.isEventsPage) return EVENT_TYPE
      if (this.isStatusesPage) return STATUS_TYPE
      return ''
    },
    logsType() {
      return this.$route.query?.logTab || 'event'
    },
    selectedItemDateTime() {
      return this.$route.query.dateTime
    },
    selectedItemIsServerTime() {
      return !!+this.$route.query.isServerTime
    },
    selectedItemDevice() {
      return this.$route.query.deviceId
    },
    selectedItemTerminal() {
      return this.$route.query.terminalId
    },
    convertedFromDate() {
      if (!this.fromDate) return ''
      return moment(this.fromDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    convertedToDate() {
      if (!this.toDate) return ''
      return moment(this.toDate).format(DEFAULT_DATE_TIME_FORMAT)
    },
    selectedEventTypesCSV() {
      const mergedArray = [...this.selectedEventTypeGroups, ...this.selectedEventTypes.map((item) => item.id)].flat()
      return [...new Set(mergedArray)].join()
    },
    selectedEventsCSV() {
      return this.selectedEvents.map((item) => item.id).join()
    },
    selectedUsersCSV() {
      return this.selectedUsers.map((item) => item.id).join()
    },
    selectedBalanceHoldersCSV() {
      return this.selectedBalanceHolders.map((item) => item.value).join()
    },
    selectedServicePointsCSV() {
      return this.selectedServicePoints.map((item) => item.value).join()
    },
    selectedTerminalsCSV() {
      return this.selectedTerminals.map((item) => item.value).join()
    },
    selectedDeviceTypesCSV() {
      return this.selectedDevicesTypes.map((item) => item.name).join()
    },
    selectedDevicesCSV() {
      return this.selectedDevices.map((item) => item.value).join()
    },
    isProfilePage() {
      return this.$route.name === 'Profile'
    },
    isEventsPage() {
      return this.logsType === 'event'
    },
    isStatusesPage() {
      return this.logsType === 'status'
    },
  },

  watch: {
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
    logsType() {
      this.paginateTo()
    },
    isServerTime() {
      if (this.eventLogs.length) this.SET_EVENT_LOGS([])
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.clearLogsData()
  },

  methods: {
    ...mapActions('logs', ['loadEventLogs', 'displayWarningAlert']),
    ...mapActions('infoConstructor', [
      'loadAdditionalInfoEventTypes',
      'loadAdditionalInfoEventCodes',
      'loadSelectedAdditionalInfoEventType',
      'loadSelectedAdditionalInfoEvent',
    ]),
    ...mapActions('admin', ['loadSelectedUser']),
    ...mapActions('users', ['loadInitiators']),
    ...mapActions('companies', ['loadFilter']),
    ...mapMutations('logs', ['SET_EVENT_LOGS']),

    async initialize() {
      // this.additionalInfoEventTypesList = global.structuredClone(this.additionalInfoEventTypes)
      this.selectedCompany = +this.currentCompanyId
      this.initiatorsOptionsData = {
        loadingFunction: this.loadInitiators,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 1,
        },
        searchParamName: 'search',
        pagination: true,
      }
      this.eventTypesOptionsData = {
        loadingFunction: this.loadAdditionalInfoEventTypes,
        payload: {
          companyId: this.currentCompanyId,
          forSearch: 0,
        },
        searchParamName: 'search',
        pagination: true,
      }
      this.eventCodesOptionsData = {
        loadingFunction: this.loadAdditionalInfoEventCodes,
        payload: {
          forSearch: 1,
        },
        searchParamName: 'search',
        pagination: true,
      }
      await this.loadFilter(this.selectedCompany)
      if (this.selectedItemTerminal) {
        this.isServerTime = this.selectedItemIsServerTime
        this.selectedTerminals = [this.selectedItemTerminal]
        this.selectedDevices = this.selectedItemDevice ? [this.selectedItemDevice] : []
        this.fromDate = new Date(moment(this.selectedItemDateTime).subtract(1, 'hours').format())
        this.toDate = new Date(moment(this.selectedItemDateTime).add(15, 'minutes').format())
      } else {
        switch (this.currentRouteName) {
          case 'ServicePointCard':
            this.setSelectedServicePoints([this.instanceId])
            break
          case 'TerminalCard':
            this.setSelectedTerminals([this.instanceId])
            break
          case 'DeviceCard':
            this.setSelectedDevices([this.instanceId])
            break
          default:
            break
        }
      }
      this.searchEventTypeData = {
        payload: {},
        valueField: 'search',
        searchFunc: this.loadAdditionalInfoEventTypes,
      }
      this.searchEventData = {
        payload: {},
        valueField: 'search',
        searchFunc: this.loadAdditionalInfoEventCodes,
        setValuesAction: this.setAllAdditionalInfoEventCodes,
      }
      this.isInitialized = true
      for (const item of this.preselectedEventTypes) {
        await this.loadSelectedAdditionalInfoEventType(item)
        this.selectedEventTypes.push(this.additionalInfoSelectedEventType)
      }
      for (const item of this.preselectedEvents) {
        await this.loadSelectedAdditionalInfoEvent(item)
        this.selectedEvents.push(this.additionalInfoEvent)
      }
      if (this.isProfilePage) {
        this.selectedUsers = [this.profile]
      } else {
        for (const item of this.preselectedUsers) {
          await this.loadSelectedUser({ userId: item })
          this.selectedUsers.push(this.user)
        }
      }
      this.eventTypesCheckboxes = {
        title: 'Зведені групи подій',
        checkboxes: this.eventTypeGroupsDictionary,
      }
      await this.paginateTo()
    },
    async paginateTo() {
      if (!this.isInitialized) return
      if (this.currentCompanyId !== 'undefined') {
        this.loading = true
        const eventLogsParams = {
          company: this.selectedCompany,
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sort: this.sortObject,
          eventTypes: !this.selectedEventsCSV ? this.selectedEventTypesCSV : '',
          events: this.selectedEventsCSV,
          users: this.selectedUsersCSV,
          balanceHolders: this.isBalanceHoldersFilterApplied ? this.selectedBalanceHoldersCSV : '',
          servicePoints: this.isServicePointsFilterApplied
            ? this.selectedServicePointsCSV
            : this.currentRouteName === 'ServicePointCard'
            ? this.instanceId
            : '',
          terminals: this.isTerminalsFilterApplied
            ? this.selectedTerminalsCSV
            : this.currentRouteName === 'TerminalCard'
            ? this.instanceId
            : '',
          deviceTypes: this.isDevicesTypesFilterApplied ? this.selectedDeviceTypesCSV : '',
          devices: this.isDevicesFilterApplied
            ? this.selectedDevicesCSV
            : this.currentRouteName === 'DeviceCard'
            ? this.instanceId
            : '',
          fromDate: this.convertedFromDate,
          toDate: this.convertedToDate,
          serverTime: this.isServerTime ? 1 : 0,
          packetTypes: this.isStatusesPage ? 'S' : '',
        }
        await this.loadEventLogs(eventLogsParams)
        this.loading = false
      } else {
        await this.displayWarningAlert({ message: 'Оберіть компанію' })
        await this.$router.replace('/spa')
      }
    },
    async applyFilter(type) {
      if (type === 'eventTypes') {
        const payload = {
          eventTypeId: this.selectedEventTypesCSV,
        }
        this.filterLoading = true
        await Promise.all([this.paginateTo(), this.loadAdditionalInfoEventCodes(payload)])
        this[`is${type.capitalizeFirstLetter()}FilterApplied`] =
          !!this[`selected${type.capitalizeFirstLetter()}`].length || this.selectedEventTypeGroups.length
        this.filterLoading = false
        return
      }
      this[`is${type.capitalizeFirstLetter()}FilterApplied`] = !!this[`selected${type.capitalizeFirstLetter()}`].length
      await this.paginateTo()
    },
    navigateToServicePointCard(item) {
      const filter = {
        isServerTime: this.isServerTime ? '1' : '0',
        terminalId: item.terminal_id || null,
        deviceId: item.device_id || null,
        dateTime: this.isServerTime ? item.server_packet_date_time : item.terminal_packet_date_time,
      }
      this.$router.push({
        name: 'ServicePointCard',
        params: { servicePointId: item.service_point_id },
        query: { ...filter, logTab: this.logsType },
      })
    },
    openStatusDialog(item) {
      if (item.spec_params && item.device_id) {
        this.statusDialogTitle = 'Додаткові параметри автомата'
      } else if (item.spec_params && !item.device_id) {
        this.statusDialogTitle = 'Додаткові параметри термінала'
      }
      this.statusSpecParams = item.spec_params
      this.statusDialog = true
    },
    clearLogsData() {
      this.SET_EVENT_LOGS([])
    },
  },
}
</script>
